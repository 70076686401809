<!--  -->
<template>
  <div class="Details">
    <!-- 添加家庭信息 -->
    <el-dialog title="添加家庭信息" :visible.sync="dialogFormVisible">
      <el-form :model="family" v-if="addtype == 1">
        <el-form-item label="关系" :label-width="formLabelWidth">
          <el-input v-model="family.relation"></el-input>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input v-model="family.name"></el-input>
        </el-form-item>
        <el-form-item label="工作单位" :label-width="formLabelWidth">
          <el-input v-model="family.work"></el-input>
        </el-form-item>
        <el-form-item label="职位" :label-width="formLabelWidth">
          <el-input v-model="family.post"></el-input>
        </el-form-item>
        <el-form-item label="电话" :label-width="formLabelWidth">
          <el-input v-model="family.number"></el-input>
        </el-form-item>
      </el-form>
      <el-form :model="education" v-if="addtype == 2">
        <el-form-item label="入学时间" :label-width="formLabelWidth">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="education.add_time"
            type="datetime"
            placeholder="选择开始时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="毕业时间" :label-width="formLabelWidth">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="education.del_time"
            type="datetime"
            placeholder="选择结束时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="所在学校" :label-width="formLabelWidth">
          <el-input v-model="education.school"></el-input>
        </el-form-item>
        <el-form-item label="所学专业" :label-width="formLabelWidth">
          <el-input v-model="education.major"></el-input>
        </el-form-item>
        <el-form-item label="学历" :label-width="formLabelWidth">
          <el-input v-model="education.background"></el-input>
        </el-form-item>
      </el-form>
      <el-form :model="work" v-if="addtype == 3">
        <el-form-item label="入职时间" :label-width="formLabelWidth">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="work.add_time"
            type="datetime"
            placeholder="选择开始时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="离职时间" :label-width="formLabelWidth">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="work.del_time"
            type="datetime"
            placeholder="选择结束时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="工作单位" :label-width="formLabelWidth">
          <el-input v-model="work.work"></el-input>
        </el-form-item>
        <el-form-item label="职位" :label-width="formLabelWidth">
          <el-input v-model="work.post"></el-input>
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addFamily">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 面包屑导航 -->
    <Breadcrumb :getNav="getNav" :info="this.query.name"></Breadcrumb>
    <el-card id="prin">
      <!-- 步骤条 -->
      <el-steps :active="parseInt(activeName)" finish-status="success">
        <el-step title="个人情况"></el-step>
        <el-step title="社保情况"></el-step>
        <el-step title="家庭状况"></el-step>
        <el-step title="教育履历"></el-step>
        <el-step title="工作简历"></el-step>
        <el-step title="确认提交"></el-step>
      </el-steps>
      <!-- tab切换 -->
      <el-tabs tab-position="left" v-model="activeName" :before-leave="change">
        <el-tab-pane label="个人情况" name="0">
          <!-- <el-button type="primary" @click="handlePrint">打印局部</el-button> -->
          <!-- <el-button @click="popNotice('你', 'show me the code')"
            >测试</el-button> -->
          <el-form :model="form">
            <el-row>
              <el-col :span="8">
                <el-form-item label="姓名" :label-width="formLabelWidth">
                  <el-input v-model="form.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="民族" :label-width="formLabelWidth">
                  <el-input v-model="form.nation"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="学历" :label-width="formLabelWidth">
                  <el-input v-model="form.background"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="性别" :label-width="formLabelWidth">
                  <el-radio-group v-model="form.gender" size="small">
                    <el-radio-button :label="1">男</el-radio-button>
                    <el-radio-button :label="0">女</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="婚姻状况" :label-width="formLabelWidth">
                  <el-radio-group v-model="form.get_married" size="small">
                    <el-radio-button :label="1">已婚</el-radio-button>
                    <el-radio-button :label="2">未婚</el-radio-button>
                    <el-radio-button :label="3">离异</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="户口性质" :label-width="formLabelWidth">
                  <el-radio-group v-model="form.hk_nature" size="small">
                    <el-radio-button :label="1">城镇</el-radio-button>
                    <el-radio-button :label="2">农村</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="出生日期" :label-width="formLabelWidth">
                  <el-date-picker
                    value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="form.birth"
                    type="datetime"
                    placeholder="选择时间"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="首次工作时间"
                  :label-width="formLabelWidth"
                >
                  <el-date-picker
                    value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="form.ets_time"
                    type="datetime"
                    placeholder="选择时间"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="手机号码" :label-width="formLabelWidth">
                  <el-input v-model="form.number"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="身份证号" :label-width="formLabelWidth">
                  <el-input v-model="form.id_number"></el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="开户行名称" :label-width="formLabelWidth">
                  <el-input v-model="form.yh_open"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="银行卡账号" :label-width="formLabelWidth">
                  <el-input v-model="form.yh_number"></el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="实际居住地址"
                  :label-width="formLabelWidth"
                >
                  <el-input v-model="form.siteing"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="固定电话" :label-width="formLabelWidth">
                  <el-input v-model="form.fixed_line"></el-input> </el-form-item
              ></el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="户口地址" :label-width="formLabelWidth">
                  <el-input v-model="form.residence"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="E-mail" :label-width="formLabelWidth">
                  <el-input v-model="form.e_mail"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="紧急联系人" :label-width="formLabelWidth">
                  <el-input v-model="form.urgent_name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="紧急联系人电话"
                  :label-width="formLabelWidth"
                >
                  <el-input v-model="form.urgent_number"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <!-- 社保情况 -->
        <el-tab-pane label="社保情况" name="1">
          <el-form :model="form">
            <el-row>
              <el-col :span="10">
                <el-form-item label="公积金情况" :label-width="formLabelWidth">
                  <el-radio-group v-model="form.housing" size="small">
                    <el-radio-button :label="0">否</el-radio-button>
                    <el-radio-button :label="1">是</el-radio-button>
                  </el-radio-group>
                  <el-input
                    v-show="form.housing != 0"
                    v-model="housing"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="养老保险情况"
                  :label-width="formLabelWidth"
                >
                  <el-radio-group v-model="form.insurance" size="small">
                    <el-radio-button :label="0">否</el-radio-button>
                    <el-radio-button :label="1">是</el-radio-button>
                  </el-radio-group>
                  <el-input
                    v-show="form.insurance != 0"
                    autocomplete="off"
                    v-model="insurance"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="医疗保险情况"
                  :label-width="formLabelWidth"
                >
                  <el-radio-group v-model="form.medicare" size="small">
                    <el-radio-button :label="0">否</el-radio-button>
                    <el-radio-button :label="1">是</el-radio-button>
                  </el-radio-group>
                  <el-input
                    v-model="medicare"
                    v-show="form.medicare != 0"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="档案所在机构" :label-width="formLabelWidth">
              <el-radio-group v-model="form.record" size="mini">
                <el-radio-button
                  v-for="item in radiolist"
                  :label="item.id"
                  :key="item.id"
                  >{{ item.name }}</el-radio-button
                >
              </el-radio-group>
              <!-- <el-input v-model="form.record" style="width:200px"></el-input> -->
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <!-- 家庭状况 -->
        <el-tab-pane label="家庭状况" name="2">
          <el-button
            type="primary"
            @click=";(dialogFormVisible = true), (addtype = 1)"
            >添加信息</el-button
          >
          <el-table :data="form.family" style="width: 100%">
            <el-table-column prop="relation" label="关系" width="180">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="180">
            </el-table-column>
            <el-table-column prop="work" label="工作单位"> </el-table-column>
            <el-table-column prop="post" label="职位"> </el-table-column>
            <el-table-column prop="number" label="电话"> </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="familyDel(scope.$index)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="教育履历" name="3">
          <el-button
            type="primary"
            @click=";(dialogFormVisible = true), (addtype = 2)"
            >添加信息</el-button
          >
          <el-table :data="form.education" style="width: 100%">
            <el-table-column prop="relation" label="日期">
              <template slot-scope="scope">
                {{ scope.row.add_time }}-至-{{ scope.row.del_time }}
              </template>
            </el-table-column>
            <el-table-column prop="school" label="所在院校" width="180">
            </el-table-column>
            <el-table-column prop="major" label="所学专业" width="120">
            </el-table-column>
            <el-table-column prop="background" label="学历" width="120">
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="educationDel(scope.$index)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="工作简历" name="4">
          <el-button
            type="primary"
            @click=";(dialogFormVisible = true), (addtype = 3)"
            >添加信息</el-button
          >
          <el-table :data="form.work" style="width: 100%">
            <el-table-column prop="relation" label="日期">
              <template slot-scope="scope">
                {{ scope.row.add_time }}-至-{{ scope.row.del_time }}
              </template>
            </el-table-column>
            <el-table-column prop="work" label="工作单位"> </el-table-column>
            <el-table-column prop="post" label="职位" width="120">
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="workDel(scope.$index)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="确认提交" name="5">
          <div class="text-info">
            本人确认，以上《员工信息表》中提供的信息真实准确，如有任何信息变动，须5个工作日内书面通知。若填写内容与实际情况不符，本人愿承担相关责任。
          </div>
          <div><el-checkbox v-model="form.affirm">确认同意</el-checkbox></div>
          <div class="btn">
            <el-button
              type="primary"
              class="addbtn"
              @click="addbtn()"
              v-if="!this.query.name"
              >提交信息</el-button
            >
            <el-button
              type="primary"
              class="addbtn"
              @click="editbtn()"
              v-if="this.query.name"
              >确认修改</el-button
            >
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Breadcrumb from '@/components/breadcrumb.vue'
import { getEditInfo, getAddUserInfo, getAdminInfo } from '@/api/user'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    Breadcrumb,
  },
  data() {
    //这里存放数据
    return {
      rules: {
        add_time: [
          { required: true, message: '请选择入学时间', trigger: 'blur' },
        ],
        del_time: [
          { required: true, message: '请选择毕业时间', trigger: 'blur' },
        ],
        school: [
          { required: true, message: '请输入所在学校', trigger: 'blur' },
        ],
        school: [{ required: true, message: '请输入学历', trigger: 'blur' }],
      },
      rules1: {
        add_time: [
          { required: true, message: '请选择入职时间', trigger: 'blur' },
        ],
        del_time: [
          { required: true, message: '请选择离职时间', trigger: 'blur' },
        ],
        work: [{ required: true, message: '请输入工作单位', trigger: 'blur' }],
        post: [{ required: true, message: '请输入职位', trigger: 'blur' }],
      },
      rules2: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        nation: [{ required: true, message: '请输入民族', trigger: 'blur' }],
        background: [
          { required: true, message: '请输入学历', trigger: 'blur' },
        ],
        gender: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        get_married: [
          { required: true, message: '请选择婚姻状况', trigger: 'blur' },
        ],
        hk_nature: [
          { required: true, message: '请选择户口性质', trigger: 'blur' },
        ],
        birth: [{ required: true, message: '请输入出生时间', trigger: 'blur' }],
        number: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        id_number: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
        ],
        yh_open: [
          { required: true, message: '请输入开户行名称', trigger: 'blur' },
        ],
        yh_number: [
          { required: true, message: '请输入银行卡账号', trigger: 'blur' },
        ],
        siteing: [
          { required: true, message: '请输入实际居住地址', trigger: 'blur' },
        ],
      },
      rules3: {
        relation: [{ required: true, message: '请输入关系', trigger: 'blur' }],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        work: [{ required: true, message: '请输入工作单位', trigger: 'blur' }],
        post: [{ required: true, message: '请输入职位', trigger: 'blur' }],
        number: [{ required: true, message: '请输入电话', trigger: 'blur' }],
      },
      // 其他选项
      housing: '',
      insurance: '',
      medicare: '',
      radiolist: [
        {
          id: '街道',
          name: '街道',
        },
        {
          id: '市区人才中心',
          name: '市区人才中心',
        },
        {
          id: '高校',
          name: '高校',
        },
        {
          id: '集体户口',
          name: '集体户口',
        },
        {
          id: '办理居住证',
          name: '办理居住证',
        },
        {
          id: '办理就业许可证',
          name: '办理就业许可证',
        },
        {
          id: '下岗或协保',
          name: '下岗或协保',
        },
      ],
      //控制input状态
      type: 0,
      type2: 0,
      type3: 0,
      //弹窗
      dialogFormVisible: false,
      addtype: 1,
      family: {
        relation: '父亲',
        name: '张大三',
        work: '工作单位',
        post: '职位',
        number: '12345678910',
      },
      education: {
        add_time: '2020-05-18 00:00:00',
        del_time: '2020-05-18 00:00:00',
        school: '上海大学',
        major: '计算机专业',
        background: '本科',
      },
      work: {
        add_time: '2020-05-18 00:00:00',
        del_time: '2020-05-18 00:00:00',
        work: '工作单位',
        post: '职位',
      },
      activeName: '0',
      form: {
        name: '张三',
        gender: 1,
        birth: '2020-05-18 00:00:00',
        get_married: 2,
        nation: '汉',
        background: '本科',
        ets_time: '2020-05-18 00:00:00',
        number: '13772432779',
        id_number: '110123200005111234',
        yh_open: '三林支行',
        yh_number: '111122222223333444',
        siteing: '上海市浦东新区三林镇三林路258号312室',
        fixed_line: '810-123456',
        postcode: 710600,
        e_mail: '123456@qq.com',
        residence: '北京朝阳',
        urgent_name: '李四',
        urgent_number: '12345678910',
        hk_nature: 1,
        insurance: '90112341234',
        medicare: '90112341234',
        housing: '90112341234',
        record: '街道',
        family: [
          {
            relation: '父亲',
            name: '张大三',
            work: '工作单位',
            post: '职位',
            number: '12345678910',
          },
          {
            relation: '父亲',
            name: '张大三',
            work: '工作单位',
            post: '职位',
            number: '12345678910',
          },
          {
            relation: '父亲',
            name: '张大三',
            work: '工作单位',
            post: '职位',
            number: '12345678910',
          },
        ],
        education: [
          {
            add_time: '2020-05-18 00:00:00',
            del_time: '2020-05-18 00:00:00',
            school: '上海大学',
            major: '计算机专业',
            background: '本科',
          },
          {
            add_time: '2020-05-18 00:00:00',
            del_time: '2020-05-18 00:00:00',
            school: '上海大学',
            major: '计算机专业',
            background: '本科',
          },
          {
            add_time: '2020-05-18 00:00:00',
            del_time: '2020-05-18 00:00:00',
            school: '上海大学',
            major: '计算机专业',
            background: '本科',
          },
        ],
        work: [
          {
            add_time: '2020-05-18 00:00:00',
            del_time: '2020-05-18 00:00:00',
            work: '工作单位',
            post: '职位',
          },
          {
            add_time: '2020-05-18 00:00:00',
            del_time: '2020-05-18 00:00:00',
            work: '工作单位',
            post: '职位',
          },
          {
            add_time: '2020-05-18 00:00:00',
            del_time: '2020-05-18 00:00:00',
            work: '工作单位',
            post: '职位',
          },
        ],
        affirm: false,
      },
      //form文本长度
      formLabelWidth: '130px',
      //分类选择options
      cities: [],
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handlePrint() {
      this.$Print('#prin')
    },
    editChannel(info) {
      if (info == 1) {
        this.type = 1
        this.form.housing = ''
      } else {
        this.type = 0
      }
    },
    editChannel2(info) {
      if (info == 1) {
        this.type2 = 1
        this.form.work_address = ''
      } else {
        this.type2 = 0
      }
    },
    editChannel3(info) {
      if (info == 1) {
        this.type3 = 1
        this.form.medicare = ''
      } else {
        this.type3 = 0
      }
    },
    addFamily() {
      if (this.addtype == 1) {
        this.form.family.push(this.family)
        this.$message({
          message: '添加成功',
          type: 'success',
        })
        this.dialogFormVisible = false
        this.family = {
          relation: '',
          name: '',
          work: '',
          post: '',
          number: '',
        }
      } else if (this.addtype == 2) {
        this.form.education.push(this.education)
        this.$message({
          message: '添加成功',
          type: 'success',
        })
        this.dialogFormVisible = false
        this.education = {
          add_time: '',
          del_time: '',
          school: '',
          major: '',
          background: '',
        }
      } else if (this.addtype == 3) {
        this.form.work.push(this.work)
        this.$message({
          message: '添加成功',
          type: 'success',
        })
        this.dialogFormVisible = false
        this.family = {
          add_time: '',
          del_time: '',
          work: '',
          post: '',
        }
      }
    },
    familyDel(index) {
      this.form.family.splice(index, 1)
    },
    educationDel(index) {
      this.form.education.splice(index, 1)
    },
    workDel(index) {
      this.form.work.splice(index, 1)
    },
    change() {
      console.log(12313123)
    },
    async editbtn() {
      if (!this.form.affirm) {
        this.$message({
          message: '请确认勾选',
          type: 'warning',
        })
        return
      }
      let form = JSON.stringify(this.form)
      form = JSON.parse(form)
      if (form.housing != 0) {
        form.housing = this.housing
      }
      if (form.insurance != 0) {
        form.insurance = this.insurance
      }
      if (form.medicare != 0) {
        form.medicare = this.medicare
      }
      let info = await getEditInfo(form)
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.$router.push({
          path: '/userlist',
        })
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
    },
    async addbtn() {
      if (!this.form.affirm) {
        this.$message({
          message: '请确认勾选',
          type: 'warning',
        })
        return
      }
      let form = JSON.stringify(this.form)
      form = JSON.parse(form)
      if (form.housing != 0) {
        form.housing = this.housing
      }
      if (form.insurance != 0) {
        form.insurance = this.insurance
      }
      if (form.medicare != 0) {
        form.medicare = this.medicare
      }
      let info = await getAddUserInfo(form)
      if (info.data.code == 0) {
        this.$message({
          message: '添加成功',
          type: 'success',
        })
        this.$router.push({
          path: '/userlist',
        })
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
      console.log(this.form)
    },
    setdata() {
      this.query = this.$route.query
      if (this.query.id) {
        this.getInfo()
      }
      this.getNav = this.$getNavn('/userlist')
    },
    // 获取详情
    async getInfo() {
      let info = await getAdminInfo({
        id: this.query.id,
      })
      if (info.data.code == 0) {
        console.log(info.data)
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'success',
        })
        this.form = info.data.data
        if (this.form.housing != 0) {
          this.housing = this.form.housing
          this.form.housing = 1
        }
        if (this.form.insurance != 0) {
          this.insurance = this.form.insurance
          this.form.insurance = 1
        }
        if (this.form.medicare != 0) {
          this.medicare = this.form.medicare
          this.form.medicare = 1
        }
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        this.form = {}
      }
    },
    popNotice(user, content) {
      let that = this
      Notification.requestPermission(function(permission) {})
      console.log(Notification.permission)

      if (Notification.permission == 'granted') {
        let notification = new Notification(user, {
          body: content,
          icon: '111',
        })

        notification.onclick = function(e) {
          that.$nextTick(() => {
            setTimeout(() => {
              //具体操作
            }, 500)
          })
          //可直接打开通知notification相关联的tab窗
          window.focus()
          notification.close()
        }
      }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.setdata()
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.btn {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}
.addbtn {
  width: 300px;
}
.text-info {
  width: 300px;
  text-indent: 2em;
  margin-bottom: 10px;
}
</style>
